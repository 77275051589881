import React, { useState } from 'react';
import _ from 'lodash';
import LessonPlanForm from './LessonPlanForm'; // Import the child component

const ParentComponent = () => {
    const [lessonOutlines, setLessonOutlines] = useState([
        {
            teacher: 'John Doe',
            subject: 'Math',
            grade: 5,
            week: 12,
            lessons: {
                monday: { standard: '', instructionalFocus: '', vocabulary: '', essentialQuestion: '' },
                tuesday: { standard: '', instructionalFocus: '', vocabulary: '', essentialQuestion: '' },
                wednesday: { standard: '', instructionalFocus: '', vocabulary: '', essentialQuestion: '' },
                thursday: { standard: '', instructionalFocus: '', vocabulary: '', essentialQuestion: '' },
                friday: { standard: '', instructionalFocus: '', vocabulary: '', essentialQuestion: '' }
            }
        }
    ]);

    const [email, setEmail] = useState(''); // State for email input
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [link, setLink] = useState(''); // State to manage the response link

    // Handle input change for lesson details
    const handleInputChange = (e, formIndex, day, field) => {
        const { value } = e.target;

        // Update the specific lesson value without modifying other parts of the state
        setLessonOutlines((prevOutlines) => {
            const updatedOutlines = [...prevOutlines];
            const updatedLessons = { ...updatedOutlines[formIndex].lessons };
            updatedLessons[day] = { ...updatedLessons[day], [field]: value };
            updatedOutlines[formIndex] = { ...updatedOutlines[formIndex], lessons: updatedLessons };
            return updatedOutlines;
        });
    };

    // Handle input change for outline details like teacher, subject, etc.
    const handleOutlineChange = (e, formIndex) => {
        const { name, value } = e.target;

        // Update the specific outline field
        setLessonOutlines((prevOutlines) => {
            const updatedOutlines = [...prevOutlines];
            updatedOutlines[formIndex] = { ...updatedOutlines[formIndex], [name]: value };
            return updatedOutlines;
        });
    };

    // Add a new lesson plan form
    const addLessonPlan = () => {
        setLessonOutlines((prevOutlines) => [
            ...prevOutlines,
            _.cloneDeep(lessonOutlines[lessonOutlines.length - 1])
        ]);
    };

    // Remove a lesson plan form
    const removeLessonPlan = (indexToRemove) => {
        setLessonOutlines((prevOutlines) => prevOutlines.filter((_, index) => index !== indexToRemove));
    };

    // Handle submission of all forms and email
    const handleSubmitAll = async () => {
        setLoading(true); // Set loading state to true

        // Map the lessonOutlines array to the desired format
        const formattedLessonOutlines = lessonOutlines.map(outline => ({
            lessonOutline: outline
        }));

        try {
            const response = await fetch('http://18.224.19.185:3001/api/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    lessonOutlines: formattedLessonOutlines,
                    email: email
                })
            });
            const data = await response.json();
            setLink(data.link); // Assuming the response contains a field 'link'
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false); // Set loading state to false
        }
    };

    return (
        <div>
            {/* Conditionally display form, loading message, or the generated link */}
            {loading ? (
                <p>Loading...</p>
            ) : link ? (
                <div>
                    <p>Lesson Plan Generated: <a href={link} target="_blank" rel="noopener noreferrer">View Lesson Plan</a></p>
                </div>
            ) : (
                <div>
                    {lessonOutlines.map((lessonOutline, index) => (
                        <div key={index}>
                            <LessonPlanForm
                                lessonOutline={lessonOutline}
                                handleInputChange={(e, day, field) => handleInputChange(e, index, day, field)}
                                handleOutlineChange={(e) => handleOutlineChange(e, index)}
                            />
                            {lessonOutlines.length > 1 ?
                            <button onClick={() => removeLessonPlan(index)}>Remove This Plan</button> : <p></p>}
                        </div>
                    ))}

                    <button onClick={addLessonPlan}>Add New Lesson Plan</button>

                    <div style={{ marginTop: '20px' }}>
                        {/* Add the email field */}
                        <label>Email to share with: </label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <button onClick={handleSubmitAll} style={{ marginLeft: '10px' }}>
                            Submit All Forms
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParentComponent;

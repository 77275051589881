import React from 'react';

const LessonPlanForm = ({ lessonOutline, handleInputChange, handleOutlineChange }) => {
  return (
    <form>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <label>Teacher: </label>
          <input
            type="text"
            name="teacher"
            value={lessonOutline.teacher}
            onChange={handleOutlineChange}
          />
        </div>
        <div>
          <label>Subject: </label>
          <input
            type="text"
            name="subject"
            value={lessonOutline.subject}
            onChange={handleOutlineChange}
          />
        </div>
        <div>
          <label>Grade: </label>
          <input
            type="number"
            name="grade"
            value={lessonOutline.grade}
            onChange={handleOutlineChange}
          />
        </div>
        <div>
          <label>Week: </label>
          <input
            type="number"
            name="week"
            value={lessonOutline.week}
            onChange={handleOutlineChange}
          />
        </div>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gap: '10px', marginTop: '20px' }}>
        <div>Day</div>
        <div>Standard</div>
        <div>Instructional Focus</div>
        <div>Vocabulary</div>
        <div>Essential Question</div>

        {Object.keys(lessonOutline.lessons).map((lessonDay) => (
          <React.Fragment key={lessonDay}>
            <div>{lessonDay.charAt(0).toUpperCase() + lessonDay.slice(1)}</div>
            <input
              type="text"
              value={lessonOutline.lessons[lessonDay].standard}
              onChange={(e) => handleInputChange(e, lessonDay, 'standard')}
            />
            <input
              type="text"
              value={lessonOutline.lessons[lessonDay].instructionalFocus}
              onChange={(e) => handleInputChange(e, lessonDay, 'instructionalFocus')}
            />
            <input
              type="text"
              value={lessonOutline.lessons[lessonDay].vocabulary}
              onChange={(e) => handleInputChange(e, lessonDay, 'vocabulary')}
            />
            <input
              type="text"
              value={lessonOutline.lessons[lessonDay].essentialQuestion}
              onChange={(e) => handleInputChange(e, lessonDay, 'essentialQuestion')}
            />
          </React.Fragment>
        ))}
      </div>
      <br />
      <br />
    </form>
  );
};

export default LessonPlanForm;
